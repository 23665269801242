$('a[href^="#"]').on('click', function () {
  const speed = 500;
  const easing = 'swing';
  const href = $(this).attr('href');
  const target = $(href == '#' || href == '' ? 'html' : href);
  const spt = parseInt($('html').css('scrollPaddingTop')) || 0;
  const smt = parseInt($(target).css('scrollMarginTop')) || 0;
  // スクロール先
  const position = target.offset().top - (spt + smt);
  $('html, body').animate({ scrollTop: position }, speed, easing);
  return false;
});

// SP nav
$(document).ready(function () {
  $('.js-nav-bt').on('click', function () {
    if ($('.js-nav-content').hasClass('is-navOn')) {
      $(".js-nav-content").removeClass('is-navOn');
      $(".js-nav-logo").removeClass('is-navOn');
      $(this).removeClass('is-navOn');
    } else {
      $(".js-nav-content").addClass('is-navOn');
      $(".js-nav-logo").addClass('is-navOn');
      $(this).addClass('is-navOn');
    }
  });
  $('.js-nav-bt.is-navOn, .js-nav-logo.is-navOn, .js-nav-link').on('click', function () {
    $(".js-nav-content.is-navOn, .js-nav-logo.is-navOn, .js-nav-bt.is-navOn").removeClass('is-navOn');
  });
});

$(window).on('load resize', function () {
  if ($('.js-height-control').length) {
    if(window.innerWidth > 768) {
      setTimeout(() => {
        const height = $('.js-height-control-base').height();
        const headHeight = $('.js-height-control-head').height();
        console.log(height, headHeight);
        $('.js-height-control').height(height - headHeight + 40);
      }, 100);
    }
  }
});
