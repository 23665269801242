import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
  trigger: '.topMv',
  start: "bottom top",
  endTrigger:'.footer',
  end: 'bottom top',
  // markers: true,
  toggleClass: {
    targets: '.js-header',
    className: 'is-on',
  },
  // once: true,
});
